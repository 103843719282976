<template>
  <v-card>
    <v-tabs grow>
      <v-tab v-for="platform in platforms" :key="platform.id">
        <h1 v-if="platform.id == 'ESTHEDERM'">
          <img src="/media/logos/logo_esthederm.svg" height="32" width="150" />
        </h1>
        <h1 v-else>
          <img src="/media/logos/logo_bioderma.svg" height="30" width="150" />
        </h1>
      </v-tab>
      <v-tab-item v-for="platform in platforms" :key="platform.id">
        <v-tabs show-arrows grow>
          <v-tab
            v-for="item in brands.filter(b => {
              return b.platforms.find(p => p.id === platform.id);
            })"
            :key="item.id"
            >{{ item.title }}</v-tab
          >

          <v-tab-item
            v-for="item in brands.filter(b => {
              return b.platforms.find(p => p.id === platform.id);
            })"
            :key="item.id"
          >
            <v-container fluid>
              <v-row>
                <v-col
                  v-for="product in products.filter(p => {
                    return p.brandId === item.id;
                  })"
                  :key="product.id"
                  cols="12"
                  :sm="6"
                  :md="6"
                  :lg="4"
                  :xl="3"
                >
                  <a @click="addProduct(product)">
                    <v-card class="d-flex align-items-stretch h-100">
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-img
                              height="180px"
                              :contain="true"
                              :src="`${product.image.mediaLink}/350`"
                            ></v-img>
                            <v-card-title
                              primary-title
                              class="layout justify-center"
                              >{{ product.title }}</v-card-title
                            >
                            <v-card-text>{{ product.description }}</v-card-text>
                            <v-card-text class="price"
                              >{{ product.euros }}€</v-card-text
                            >
                          </v-col>
                        </v-row>
                        <v-row
                          style=" align-items: center;justify-content: center; "
                        >
                          <v-card-actions>
                            <v-btn
                              class="subtitle-2"
                              color="orange"
                              text
                              @click.stop="addProduct(product)"
                              v-if="!getLine(product)"
                            >
                              Añadir al carrito
                            </v-btn>
                            <div class="d-flex align-items-center " v-else>
                              <QuantitySelector
                                :line="getLine(product)"
                              ></QuantitySelector>
                            </div>
                          </v-card-actions>
                        </v-row>
                      </v-container>
                    </v-card>
                  </a>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { ADD_PRODUCT } from "@/core/services/store/cart.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import QuantitySelector from "../content/widgets/QuantitySelector";

export default {
  name: "shop",
  components: {
    QuantitySelector
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Tienda" }]);
  },
  methods: {
    addProduct: function(product) {
      this.$store.dispatch(ADD_PRODUCT, product);
    },
    getLine(product) {
      return this.cart.lines.find(l => l.product.id === product.id);
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
  computed: {
    ...mapGetters(["brands", "products", "platforms", "cart"])
  }
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
.v-card__title {
  font-size: 19px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  padding-top: 6px;
}
.v-card__text {
  font-size: 16px;
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 6px;
  padding-top: 4px;
}
.price {
  padding-top: 9px;
  font-weight: 600;
}
.v-slide-group-content,
.v-tabs-bar-contentt {
  align-items: center !important;
  justify-content: center !important;
}
</style>
